import { StyledBullet, StyledBullets, StyledContainer, StyledDescription, StyledHeader, StyledInnerContainer, StyledTitle } from './styles'

import { Box } from "grommet";
import PropTypes from "prop-types";
import React from "react";
import shortid from "shortid";
import styled from "styled-components";

const Projects = ({ projects, icon }) => {
  return (
    <StyledContainer>
      <StyledTitle>
        <div id="header">
          <h2 dangerouslySetInnerHTML={{ __html: projects.title }} />
        </div>
      </StyledTitle>
      {projects.nodes.map(project => (
        <StyledInnerContainer key={shortid.generate()}>
          <StyledHeader>
            <Box direction="row-responsive" justify="between">
              <span>
                <p dangerouslySetInnerHTML={{ __html: project.name }} />
                <p>{` | ${project.stack} | `}</p>
                <p dangerouslySetInnerHTML={{ __html: project.github }} />
              </span>
              <span>
                <p>{project.date}</p>
              </span>
            </Box>
          </StyledHeader>
          <StyledDescription>{project.description}</StyledDescription>
          <StyledBullets>
            {project.responsibilites.map((point, index) => (
              <StyledBullet key={shortid.generate()}>
                <img
                  src={icon.icon}
                  key={"img-" + index}
                  alt={icon.alt}
                  style={{ height: 10, width: 10 }}
                />
                <span dangerouslySetInnerHTML={{ __html: point }} />
              </StyledBullet>
            ))}
          </StyledBullets>
        </StyledInnerContainer>
      ))}
    </StyledContainer>
  );
};

Projects.propTypes = {
  projects: PropTypes.object
};

export default Projects;
