import { Box } from "grommet";
import React from 'react';
import styled from "styled-components";

const Container = styled(Box)``;

export const StyledContainer = props => <Container {...props} />

const Title = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  margin: 5px 0;

  #header {
    width: 100%;
    text-align: center;
    position: relative;
  }

  #header:after {
    content: "";
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.primaryDarker};
    position: absolute;
    left: 0;
    top: 60%;
    z-index: 1;
  }

  h2 {
    font-style: italic;
    text-transform: uppercase;
    background-color: white;
    width: auto;
    display: inline-block;
    z-index: 3;
    color: ${props => props.theme.colors.primaryDarker};
    position: relative;
    margin: 0 5px;

    .first-letter {
      font-size: 1.2em;
      font-weight: bold;
    }
  }
`;

export const StyledTitle = props => <Title {...props} />

const Header = styled(Box)`
  span {
    line-height: 0;
    p {
      display: inline;
      text-transform: uppercase;
      .book-title {
        font-style: italic;
        font-weight: bold;
        color: ${props => props.theme.colors.primaryDark};
        .first-letter {
          font-size: 1.2em;
          font-weight: bold;
        }
      }
      a {
        color: ${props => props.theme.colors.primaryDark};
        display: inline-block;
        position: relative;
        text-decoration: none;
        &:before {
          background-color: ${props => props.theme.colors.primaryDark};
          content: "";
          height: 1px;
          position: absolute;
          bottom: -1px;
          left: 50%;
          transform: translateX(-50%);
          transition: width 0.3s ease-in-out;
          width: 0;
        }
        &:hover {
          color: ${props => props.theme.colors.fadedGrey};
          text-decoration: none;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const StyledHeader = props => <Header {...props} />

const Description = styled.p`
  font-style: italic;
  font-weight: bold !important;
  color: ${props => props.theme.colors.fadedGrey};
  opacity: 0.8;
`;

export const StyledDescription = props => <Description {...props} />

const Bullets = styled(Box)``;

export const StyledBullets = props => <Bullets {...props} />

const Bullet = styled.span`
  margin: 2px 0 2px 10px;
  display: grid;
  grid-template-columns: 5% auto;
  grid-teamplte-areas: "image point";
  align-items: center;
  img {
    display: inline-block;
    grid-area: "image";
  }
  p {
    grid-area: "point";
    color: black;
    .tech-label {
      font-style: italic;
      color: ${props => props.theme.colors.primaryDark};
    }
    a {
      color: ${props => props.theme.colors.primaryDark};
      display: inline-block;
      position: relative;
      text-decoration: none;
      &:before {
        background-color: ${props => props.theme.colors.primaryDark};
        content: "";
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: width 0.3s ease-in-out;
        width: 0;
      }

      &:hover {
        color: ${props => props.theme.colors.fadedGrey};
        text-decoration: none;
        &:before {
          width: 100%;
        }
      }
    }
  }
`;

export const StyledBullet = props => <Bullet {...props} />

const InnerContainer = styled(Box)`
  margin: 5px 0;
`;

export const StyledInnerContainer = props => <InnerContainer {...props} />
