import { StyledContainer, StyledTitle } from "./styles";

import { Box } from "grommet";
import PropTypes from "prop-types";
import React from "react";
import media from "styled-media-query";
import shortid from "shortid";
import styled from "styled-components";

const BraceWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 0 100% 0;
  grid-template-areas: "left content right";
  justify-content: stretch;

  ${media.greaterThan("medium")`
    grid-template-columns: 5% auto 5%;
    grid-template-areas: "left content right";
  `}
`;

const SkillsWrapper = styled(Box)`
  grid-area: content;
`;

const StyledLeftBrace = styled(Box)`
  display: none;
  grid-area: left;
  font-size: 38px;
  align-self: center;
  padding-bottom: 4px;
  font-family: helvetica neue;
  font-weight: 100;
  color: ${props => props.theme.colors.primaryDark};

  ${media.greaterThan("medium")`
    display: block;
  `}
`;

const StyledRightBrace = styled(StyledLeftBrace)`
  display: none;
  grid-area: right;
  justify-self: end;

  ${media.greaterThan("medium")`
    display: block;
  `}
`;

const TechnicalSkillsContainer = styled(Box)`
  display: flex;
  flex-flow: row wrap;
`;

const StyledHeader = styled(Box)`
  width: 30%;
  ${media.greaterThan("medium")`
    width: 15%;
  `}
  img {
    grid-area: icon;
    margin-right: 2px;
  }
  p {
    grid-area: name;
    display: inline;
    text-transform: uppercase;
    .book-title {
      font-style: italic;
      font-weight: normal;
      color: ${props => props.theme.colors.primaryDark};
      .first-letter {
        font-size: 1.2em;
        font-weight: bold;
      }
    }
    a {
      color: ${props => props.theme.colors.primaryDark};
      display: inline-block;
      position: relative;
      text-decoration: none;
      &:before {
        background-color: ${props => props.theme.colors.primaryDark};
        content: "";
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: width 0.3s ease-in-out;
        width: 0;
      }
      &:hover {
        color: ${props => props.theme.colors.fadedGrey};
        text-decoration: none;
        &:before {
          width: 100%;
        }
      }
    }
  }
`;

const StyledSkills = styled(Box)`
  width: 70%;
  display: flex;
  flex-flow: row wrap;

  ${media.greaterThan("medium")`
    width: 85%;
  `}
`;

const StyledSkill = styled.span``;

const TechnicalSkills = ({ technicalSkills }) => {
  return (
    <StyledContainer>
      <StyledTitle>
        <div id="header">
          <h2 dangerouslySetInnerHTML={{ __html: technicalSkills.title }} />
        </div>
      </StyledTitle>
      <BraceWrapper>
        <StyledLeftBrace>&#123; </StyledLeftBrace>
        <SkillsWrapper>
          {technicalSkills.nodes.map((node, index) => (
            <TechnicalSkillsContainer key={shortid.generate()}>
              <StyledHeader>
                <p>{node.label}</p>
              </StyledHeader>
              <StyledSkills>
                {node.skills.length > 0 &&
                  node.skills.map(skill => (
                    <StyledSkill key={shortid.generate()}>
                      <p dangerouslySetInnerHTML={{ __html: skill }} />
                    </StyledSkill>
                  ))}
              </StyledSkills>
            </TechnicalSkillsContainer>
          ))}
        </SkillsWrapper>

        <StyledRightBrace>&#125; </StyledRightBrace>
      </BraceWrapper>
    </StyledContainer>
  );
};

TechnicalSkills.propTypes = {
  technicalSkills: PropTypes.object
};

export default TechnicalSkills;
