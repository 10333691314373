import { Box } from "grommet";
import PropTypes from "prop-types";
import React from "react";
import media from "styled-media-query";
import shortid from "shortid";
import styled from "styled-components";

const StyledH1 = styled.h1`
 h1 {
   font-size: 16px !important;
 }
`
const StyledContainer = styled(Box)`
  display: flex;
  flex-flow: row;
  justify-content: between;
`;

const StyledLeft = styled(Box)`
  width: 55% 
  ${media.greaterThan("medium")`
    width: 70%
  `}
  h1 {
    margin: 2px 0;
    text-transform: uppercase;
    font-weight: bold;
    color: ${props => props.theme.colors.primaryDark};
    .first-letter {
      font-size: 1.2em;
      font-weight: bold;
    }
  }
`;

const StyledAddress = styled.p`
  font-style: italic;
  font-weight: bold !important;
  color: ${props => props.theme.colors.fadedGrey};
  opacity: 0.8;
`;

const StyledRight = styled(Box)`
  width: 45%;
  margin-top: 7px;
  ${media.greaterThan("medium")`
    width: 30%
    margin-top: 0;
  `}
`;

const StyledSocial = styled(Box)`
  margin-bottom: 5px;
  display: grid;
  grid-template-areas: "link image";
  grid-template-columns: auto 15px;
  justify-items: end;
  align-items: center;
  ${media.greaterThan("medium")`
    margin-bottom: 0;
  `}
  img {
    grid-area: image;
  }
  span {
    grid-area: link;
    a {
      color: ${props => props.theme.colors.primaryDark};
      display: inline-block;
      position: relative;
      text-decoration: none;
      &:before {
        background-color: ${props => props.theme.colors.primaryDark};
        content: "";
        height: 1px;
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: width 0.3s ease-in-out;
        width: 0;
      }

      &:hover {
        color: ${props => props.theme.colors.fadedGrey};
        text-decoration: none;
        &:before {
          width: 100%;
        }
      }
  }
`;

const Header = ({ header, icons }) => {
  return (
    <StyledContainer>
      <StyledLeft>
        <StyledH1 dangerouslySetInnerHTML={{ __html: header.name }} />
        <StyledAddress>{header.address}</StyledAddress>
        <p>{header.bio}</p>
      </StyledLeft>
      <StyledRight>
        {header.social.map((node, index) => {
          const target = icons.find(icon => icon.alt === node.label);
          return (
            <StyledSocial key={shortid.generate()}>
              <span dangerouslySetInnerHTML={{ __html: node.link }} />
              <img
                src={target.icon}
                key={"img-" + index}
                alt={target.alt}
                style={{ height: 10, width: 10 }}
              />
            </StyledSocial>
          );
        })}
      </StyledRight>
    </StyledContainer>
  );
};

Header.propTypes = {
  header: PropTypes.object
};

export default Header;
