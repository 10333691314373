import Button from "./button";
import JsPdf from "jspdf";
import React from "react";
import html2canvas from "html2canvas";

const getCurrentDate = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();
  let hr = today.getHours(); // => 9
  let min= today.getMinutes(); // =>  30
  let s = today.getSeconds(); // => 51

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }


  if (hr < 10) {
    hr = "0" + hr;
  }

  if (min < 10) {
    min = "0" + min;
  }

  if (s < 10) {
    s = "0" + s;
  }

  //today = mm + "-" + dd + "-" + yyyy;
  today = `${hr}-${min}-${s}`
  // return today;
  return `2019_${today}`;
};

const PrintButton = ({ id, label }) => (
  <Button
    onClick={() => {
      const page = document.getElementById("canvas");
      html2canvas(page).then(canvas => {
        const imgData = canvas.toDataURL("image/png");

        const imgWidth = 210;
        const pageHeight = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        const doc = new JsPdf("p", "mm");
        let position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`IMG_Daryll_Cheng_Resume_${getCurrentDate()}.pdf`);
      });
    }}
    label={label}
    light={false}
  />
);

export default PrintButton;
