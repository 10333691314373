import { Box, Grommet, ResponsiveContext } from "grommet";
import React, { Component } from "react";
import {
  faAt,
  faBasketballBall,
  faGraduationCap,
  faListUl,
  faPaw,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import styled, { ThemeProvider } from "styled-components";

import Button from "./components/button";
import Educations from "./components/educations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./components/header";
import Jobs from "./components/jobs";
import { PDFExport } from "@progress/kendo-react-pdf";
import PrintButton from "./components/printButton";
import Projects from "./components/projects";
import ReactDOMServer from "react-dom/server";
import TechnicalSkills from "./components/technical-skills";
import canvg from "canvg";
import data from "./data";
import { deepMerge } from "grommet/utils";
import { grommet } from "grommet/themes";
import media from "styled-media-query";

const StyledContainer = styled(Box)`
  background-color: grey;
  padding: 0;

  ${media.greaterThan("medium")`
    padding: 50px;
  `}
`;

const customBreakpoints = deepMerge(grommet, {
  global: {
    font: {
      family: "Montserrat"
    },
    edgeSize: {
      large: "64px",
      xlarge: "100px"
    },
    h1: {
      fontSize: "44px",
      lineHeight: "normal",
      fontWeight: "normal"
    },
    h2: {
      fontSize: "32px",
      lineHeight: "normal",
      fontWeight: "normal"
    },
    h3: {
      fontSize: "22px",
      lineHeight: "normal",
      fontWeight: "normal"
    },
    p: {
      fontSize: "18px",
      lineHeight: "32px",
      fontWeight: "normal"
    }
  }
});

const StyledCanvas = styled(Box)`
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol" !important;

  h1 {
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
  }
  h2 {
    font-size: 12px;
    line-height: normal;
    font-weight: bold;
  }
  h3 {
    font-size: 10px;
    line-height: normal;
    font-weight: normal;
  }
  p {
    font-size: 8px;
    font-weight: normal;
    line-height: normal;
    margin: 2px 0;
    color: black;
  }

  min-height: 792px;
  width: 100%;
  margin: auto;

  padding: none;
  background-color: white;
  box-shadow: 5px 5px 5px black;

  overflow-x: hidden;
  overflow-y: hidden;

  ${media.greaterThan("medium")`
    width: 612px;
  `}
`;

const theme = {
  colors: {
    primary: "#3498db",
    primaryDark: "#3c5785",
    primaryDarker: "#26344c",
    primaryWhite: "#FFFFFF",
    primaryGrey: "#edeef2",
    fadedGrey: "#67656f"
  },
  fontSize: {
    xl: "2.4rem",
    lg: "1.8rem",
    md: "1.3rem",
    nm: "1rem",
    sm: "0.75rem"
  }
};

const StyledButtonContainer = styled(Box)`
  display: none;

  ${media.greaterThan("medium")`
    display: flex;
    justify-content: space-between;
    width: 612px;
    margin: 0 auto;
    margin-bottom: 20px;
    flex-flow: row wrap;
  `}
`;

class App extends Component {
  resume;

  constructor() {
    super();
    this.iconsToConvert = {
      social: [
        {
          icon: faGithub,
          alt: "github"
        },
        {
          icon: faLinkedin,
          alt: "linkedin"
        },
        {
          icon: faAt,
          alt: "email"
        },
        {
          icon: faListUl,
          alt: "resume"
        },
        {
          icon: faUserCircle,
          alt: "website"
        }
      ],
      jobs: [
        {
          icon: faBasketballBall,
          alt: "bullet basketball icon"
        }
      ],
      projects: [
        {
          icon: faPaw,
          alt: "bullet paw icon"
        }
      ],
      educations: [
        {
          icon: faGraduationCap,
          alt: "bullet graduation cap icon"
        }
      ]
    };
    this.canvLoaded = false;
  }

  exportPDF = () => {
    this.resume.save();
  };

  convertSvgToImage = obj => {
    let canv = this.refs.canvas;
    if (!this.canvLoaded) {
      this.canvLoaded = true;
      canv.getContext("2d");
      Object.values(obj).forEach(arr => {
        arr.forEach((d, i) => {
          let htmlString = ReactDOMServer.renderToStaticMarkup(
            <FontAwesomeIcon
              icon={d.icon}
              size={"3x"}
              style={{ color: "#3c5785", height: "500px", width: "500px" }}
            />
          );
          canvg(canv, htmlString);
          d.icon = canv.toDataURL("image/png");
        });
      });
      this.setState({});
    }
  };

  getCurrentDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let hr = today.getHours(); // => 9
    let min= today.getMinutes(); // =>  30
    let s = today.getSeconds(); // => 51

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }


    if (hr < 10) {
      hr = "0" + hr;
    }

    if (min < 10) {
      min = "0" + min;
    }

    if (s < 10) {
      s = "0" + s;
    }

    //today = mm + "-" + dd + "-" + yyyy;
    today = `${hr}-${min}-${s}`
    // return today;
    return `2019_${today}`;
  };

  componentDidMount() {
    this.convertSvgToImage(this.iconsToConvert);
  }

  render() {
    const { header, technicalSkills, jobs, projects, educations } = data;
    return (
      <Grommet theme={customBreakpoints}>
        <ThemeProvider theme={theme}>
          <StyledContainer>
            {!this.canvLoaded && (
              <canvas ref="canvas" style={{ display: "none" }} />
            )}
            <StyledButtonContainer>
              <Button
                label="Download as PDF"
                onClick={this.exportPDF}
                light={true}
              />
              <PrintButton id={"canvas"} label={"Download as IMG"} />
            </StyledButtonContainer>
            <PDFExport
              paperSize={"Letter"}
              fileName={`Daryll_Cheng_Resume_${this.getCurrentDate()}.pdf`}
              title=""
              subject=""
              keywords=""
              ref={r => (this.resume = r)}
            >
              <StyledCanvas
                pad={{ vertical: "small", horizontal: "medium" }}
                id="canvas"
              >
                <Header header={header} icons={this.iconsToConvert.social} />
                <TechnicalSkills technicalSkills={technicalSkills} />
                <Jobs jobs={jobs} icon={this.iconsToConvert.jobs[0]} />
                <Projects
                  projects={projects}
                  icon={this.iconsToConvert.projects[0]}
                />
                <Educations
                  educations={educations}
                  icon={this.iconsToConvert.educations[0]}
                />
              </StyledCanvas>
            </PDFExport>
          </StyledContainer>
        </ThemeProvider>
      </Grommet>
    );
  }
}

export default App;
