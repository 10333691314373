import { StyledBullet, StyledBullets, StyledContainer, StyledDescription, StyledHeader, StyledInnerContainer, StyledTitle } from './styles'

import { Box } from "grommet";
import PropTypes from "prop-types";
import React from "react";
import shortid from "shortid";
import styled from "styled-components";

const Jobs = ({ jobs, icon }) => {
  return (
    <StyledContainer>
      <StyledTitle>
        <div id="header">
          <h2 dangerouslySetInnerHTML={{ __html: jobs.title }} />
        </div>
      </StyledTitle>
      {jobs.nodes.map(job => (
        <StyledInnerContainer key={shortid.generate()}>
          <StyledHeader>
            <Box direction="row-responsive" justify="between">
              <span>
                <p dangerouslySetInnerHTML={{ __html: job.company }} />
                <p>{` | ${job.title} | ${job.location}`}</p>
              </span>
              <span>
                <p>{job.date}</p>
              </span>
            </Box>
          </StyledHeader>
          <StyledDescription>{job.description}</StyledDescription>
          <StyledBullets>
            {job.responsibilites.map((point, index) => (
              <StyledBullet key={shortid.generate()}>
                <img
                  src={icon.icon}
                  key={"img-" + index}
                  alt={icon.alt}
                  style={{ height: 10, width: 10 }}
                />
                <span dangerouslySetInnerHTML={{ __html: point }} />
              </StyledBullet>
            ))}
          </StyledBullets>
        </StyledInnerContainer>
      ))}
    </StyledContainer>
  );
};

Jobs.propTypes = {
  jobs: PropTypes.object
};

export default Jobs;
