import { StyledBullet, StyledBullets, StyledContainer, StyledDescription, StyledHeader, StyledInnerContainer, StyledTitle } from './styles'

import { Box } from "grommet";
import PropTypes from "prop-types";
import React from "react";
import shortid from "shortid";
import styled from "styled-components";

const Educations = ({ educations, icon }) => {
  return (
    <StyledContainer>
      <StyledTitle>
        <div id="header">
          <h2 dangerouslySetInnerHTML={{ __html: educations.title }} />
        </div>
      </StyledTitle>
      {educations.nodes.map(education => (
        <StyledInnerContainer key={shortid.generate()}>
          <StyledHeader>
            <Box direction="row-responsive" justify="between">
              <span>
                <p dangerouslySetInnerHTML={{ __html: education.name }} />
              </span>
              <span>
                <p>{education.date}</p>
              </span>
            </Box>
          </StyledHeader>
          <StyledDescription>{education.degree}</StyledDescription>
          <StyledBullets>
            {education.achievements.length > 0 &&
              education.achievements.map((point, index) => (
                <StyledBullet key={shortid.generate()}>
                  <img
                    src={icon.icon}
                    key={"img-" + index}
                    alt={icon.alt}
                    style={{ height: 10, width: 10 }}
                  />
                  <span dangerouslySetInnerHTML={{ __html: point }} />
                </StyledBullet>
              ))}
          </StyledBullets>
        </StyledInnerContainer>
      ))}
    </StyledContainer>
  );
};

Educations.propTypes = {
  educations: PropTypes.object
};

export default Educations;
