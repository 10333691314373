export default {
  header: {
    name: `<h1><span class="first-letter">D</span>aryll <span class="first-letter">C</span>heng</h1>`,
    address: `San Francisco Bay Area, CA`,
    bio: `Behavior therapist turned engineer now combining computer science with a deep understanding of human psychology to create the most compelling and immersive web experiences`,
    social: [
      {
        label: `linkedin`,
        link: `<p><a href="https://www.linkedin.com/in/daryllcheng" target="_blank">www.linkedin.com/in/daryllcheng</a></p>`,
      },
      {
        label: `github`,
        link: `<p><a href="https://www.github.com/daryllcheng" target="_blank">www.github.com/daryllcheng</a></p>`,
      },
      {
        label: `email`,
        link: `<p><a href="mailto:daryllcsh@gmail.com" target="_blank">daryllcsh@gmail.com</a></p>`,
      },
      {
        label: `resume`,
        link: `<p><a href="https://www.daryllresu.me" target="_blank">www.daryllresu.me</a></p>`,
      },
      {
        label: `website`,
        link: `<p><a href="http://www.daryll.io" target="_blank">www.daryll.io</a></p>`,
      },
    ]
  },
  technicalSkills: {
    title: `<h2>&lt; <span class="first-letter">T</span>echnical <span class="first-letter">S</span>kills &gt;</h2>`,
    nodes: [
      {
        label: `Strong`,
        skills: [
          `JavaScript ES6 |`,
          `&nbsp; React + Redux |`,
          `&nbsp; Vue + Vuex |`,
          `&nbsp; GatsbyJS |`,
          `&nbsp; Angular |`,
          `&nbsp; Sass |`,
          `&nbsp; HTML5/CSS3 |`,
          `&nbsp; Node |`,
          `&nbsp; Hugo`
        ]
      },
      {
        label: `Experienced`,
        skills: [
          `WordPress |`,
          `&nbsp; Liquid |`,
          `&nbsp; GraphQL |`,
          `&nbsp; Egg.js |`,
          `&nbsp; PWA |`,
          `&nbsp; SEO |`,
          `&nbsp; Express |`,
          `&nbsp; MongoDB |`,
          `&nbsp; Python |`,
          `&nbsp; Golang |`,
          `&nbsp; JQuery`
        ]
      },
      {
        label: `Languages`,
        skills: [
          `English |`,
          `&nbsp; Cantonese |`,
          `&nbsp; Mandarin |`,
          `&nbsp; NBA Rumors`,
        ]
      }
    ]
  },
  jobs: {
    title: `<h2>&lt; <span class="first-letter">W</span>ork <span class="first-letter">E</span>xperience &gt;</h2>`,
    nodes: [
      {
        company: `<p class="book-title"><span class="first-letter">5</span>Miles & <span class="first-letter">C</span>yberMiles</p>`,
        title: `Frontend Engineer`,
        location: `Beijing, China`,
        date: `Apr 18 - Feb 19`,
        description: `5Miles is the 3rd largest C2C marketplace in the USA and its partner CyberMiles is a top 100 cryptocurrency and blockchain platform`,
        responsibilites: [
          `<p>Designed and created the CyberMiles Explorer Webapp with Vue & Vuex + Egg.js, fetched and dissected blocks & transactions from our Mainnet (<a href="https://www.cmttracking.io" target="_blank">www.cmttracking.io</a>) & TestNet (<a href="https://testnet.cmttracking.io" target="_blank">testnet.cmttracking.io</a>) through Web3-cmt.js, and displayed every aspect of our CyberMiles DPOS model to CMT stakeholders</p>`,
          `<p>Enforced SEO for <a href="https://www.cybermiles.io" target="_blank">www.cybermiles.io</a> in September, resulting in reduction of site load speed by over 2 seconds, and increases in users (+67%), organic traffic (+94%), and click through rate (+20%) before the MainNet launched in October</p>`,
          `<p>Migrated cybermiles.io from WordPress to a JAMstack architecture with Gatsby.js (React + GraphQL), Contentful (Headless CMS) and Netlify (Host) to increase speed, security and scalability</p>`,
          `<p>Introduced new features/improvements for both the <a href="https://www.5miles.com" target="_blank">www.5miles.com</a> Webapp and its Progressive Web App (PWA) platforms built with Angular and Sails.js: listings, reviews + ratings, product categorization & tagging, search auto completion, and many more</p>`
        ]
      },
      {
        company: `<p class="book-title"><span class="first-letter">T</span>hryve</p>`,
        title: `Software Engineer`,
        location: `Santa Clara, CA`,
        date: `Jan 18 - Apr 18`,
        description: `The world's first Gut Health company that incorporates personalized probiotics and microbiome testing`,
        responsibilites: [
          `<p>Migrated Thryve’s conversion funnel to a fully customized Shopify e-commerce platform (<a href="https://www.thryveinside.com" target="_blank">www.thryveinside.com</a>) using Liquid template Language, JavaScript, and Sass, resulting in a +300% uplift in revenue</p>`,
          `<p>Acquired significant growth in customer base and retention through the introduction of various features (kit activation, onboarding interactive survey, individually tailored user reports, etc) using React and Flask. (<a href="https://app.thryveinside.com" target="_blank">app.thryveinside.com</a>)</p>`
        ]
      },
      {
        company: `<p class="book-title"><span class="first-letter">F</span>reelance</p>`,
        title: `Software Engineer`,
        location: ``,
        date: `May 17 - Present`,
        description: ``,
        responsibilites: [
          `<p>Leveraged the speed and flexibility of Hugo to generate multiple fully customized websites (<a href="https://www.litylang.org" target="_blank">www.litylang.org</a> & <a href="https://www.noomiwallet.com" target="_blank">www.noomiwallet.com</a>) for clients with tight deadlines</p>`
        ]
      }
    ]
  },
  projects: {
    title: `<h2>&lt; <span class="first-letter">O</span>pen-Source <span class="first-letter">A</span>pplications &gt;</h2>`,
    nodes: [
      {
        name: `<p class="book-title"><span class="first-letter">T</span>ie-Chai</p>`,
        stack: `React + Redux, Golang, PostgreSQL, Redis, Firebase, AWS, Enzyme`,
        github: `<a href="https://github.com/Space-Pirahnas/tie-chai" target="_blank">github.com/Space-Pirahnas/tie-chai</a>`,
        date: `May 17`,
        description: `Connect with like-minded individuals with interest and location based matching algorithm`,
        responsibilites: [
          `<p>Engineered front-end architecture using React for performant view rendering and Redux for improved code modularization and progressive scaling</p>`,
          `<p>Implemented Golang for an efficient back-end with high-performance service delivery and increased capacity for scaling</p>`
        ]
      },
      {
        name: `<p class="book-title"><span class="first-letter">S</span>eeThroughMee</p>`,
        stack: `React.js, Node.js, Express.js`,
        github: `<a href="https://github.com/ReUP-HR/seethroughme" target="_blank">github.com/ReUP-HR/seethroughme</a>`,
        date: `Apr 17`,
        description: `Use machine learning to convert any image into its text-based description in a variety of languages`,
        responsibilites: [
          `<p>Integrated <a href="https://www.ibm.com/watson/services/visual-recognition/" target="_blank">IBM Watson Visual Recognition</a> and <a href="https://cloud.google.com/translate/docs/" target="_blank">Google Translate</a> technologies with <a href="https://www.ibm.com/watson/services/speech-to-text/" target="_blank">IBM Watson Speech to Text</a> and <a href="https://opensource.google.com/projects/tesseract" target="_blank">Google’s Tesseract Optical Character Recognition engine</a> to enable pronunciations of translated signs or images of objects</p>`
        ]
      }
    ]
  },
  educations: {
    title: `<h2>&lt; <span class="first-letter">E</span>ducation &gt;</h2>`,
    nodes: [
      {
        name: `<p class="book-title"><span class="first-letter">U</span>niversity Of California, Berkeley</p>`,
        degree: `Bachelor of Arts in Psychology – GPA 3.979/4.0 with Highest Distinction in General Scholarship`,
        date: `May 14`,
        achievements: [
          `<p>Phi Beta Kappa; Psi Chi; The National Society of Collegiate Scholars; Golden Key International Honors Society</p>`
        ]
      },
      {
        name: `<p class="book-title"><span class="first-letter">H</span>ack Reactor - San Francisco</p>`,
        degree: `Advanced Software Development Intensive`,
        associations: ``,
        date: `May 17`,
        achievements: []
      }
    ]
  }
};
