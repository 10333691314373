import { Box, Button } from 'grommet'

import React from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  font-size: 1em;
  font-weight: normal;
  padding: 0.5em 2em;
  border-radius: 4px 4px 4px 4px;
  transition: all 500ms ease;
  text-transform: uppercase;

  color: ${props => props.theme.colors.primaryWhite};
  box-shadow: inset 0 0 0 1px
    ${props =>
      props.light
        ? props.theme.colors.primaryWhite
        : props.theme.colors.primaryDark};
  background: ${props =>
    props.light ? "transparent" : props.theme.colors.primaryDark};
  border: none;

  &:hover {
    background-image: linear-gradient(to right, #0270ff, #33e5d3);
    box-shadow: 0 0 1px 0 rgba(31, 172, 255, 0.45);
    background-origin: border-box;
  }
`

const GrommetButton = ({ label, link, onClick, light }) => {
  return (
    <Box>
      {link ? (
        light ? (
          <StyledButton label={label} href={link} />
        ) : (
          <StyledButton light label={label} href={link} />
        )
      ) : light ? (
        <StyledButton label={label} onClick={onClick} />
      ) : (
        <StyledButton light label={label} onClick={onClick} />
      )}
    </Box>
  )
}

export default GrommetButton
